﻿@import "Utilities/fontsOpenSans";
@import "Utilities/variables";

body {
    padding: 0;
    margin: 0;
    background: #f6f6f6;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: #333;
}

header {  
    margin-bottom: 30px;
}

main {
    > div.container {
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid $borders-color;
        border-radius: 6px;
        box-shadow: 0 0 10px $borders-color;
        overflow: auto;
        min-height: 500px;
    }
}

footer {
    .version {
        font-size: 70%;
        color: #BCBABA;
    }
}

@font-face {
    font-family: 'FontAwesome2';
    src: url('../lib/font-awesome/fonts/fontawesome-webfont.eot');
    src: url('../lib/font-awesome/fonts/fontawesome-webfont.eot#iefix') format('embedded-opentype'), url('../lib/font-awesome/fonts/fontawesome-webfont.woff2') format('woff2'), url('../lib/font-awesome/fonts/fontawesome-webfont.woff') format('woff'), url('../lib/font-awesome/fonts/fontawesome-webfont.ttf') format('truetype'), url('../lib/font-awesome/fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
