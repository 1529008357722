﻿@import "Utilities/variables";

label.required:after {
    content: "*";
    color: $main-red;
    padding-left: 0.2em;
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #245580;
    background-image: linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
}

body .validation-summary-errors {
    color: #b94a48;
}

body .validation-summary-valid {
    display: none;
}

.page-title{
    margin-bottom: 4em;
}

form .row, .form .row{
    margin-bottom: 15px;
}

.text-underline {
    text-decoration: underline;
}