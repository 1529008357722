﻿@import "Utilities/variables";

body.login-layout {
    .text-blue {
        color: #487ec1;
        font-weight: bold;
    }

    header {
        margin-bottom: 80px;

        nav {
            border-bottom: 1px solid #d4d4d4;
            background: #FFF;

            .navbar-brand {
                margin: auto;
            }
        }
    }

    .centered-box {
        margin: 0 auto 50px auto;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid $borders-color;
        border-radius: 6px;
        box-shadow: 0 0 10px $borders-color;
        overflow: auto;
        max-width: 800px;

        .box-title {
            margin-bottom: 1.5em;
        }

        .box-message {
            font-size: 16px;
        }

        &.two-factor {
            max-width: 1000px;
        }
    }

    div.section {
        margin-bottom: 3em;
    }

    .additional-title {
        font-size: 1.5rem;
        margin-bottom: 0;
        position: relative;
        top: -40px;
    }

    .validation-summary-errors {
        padding-left: 0;

        ul {
            list-style-type: none;
            padding-left: 0;
        }
    }
}


/***************************    LOGIN PAGE STYLE   ******************************/
.login-page {

    div.logo-col {
        border-right: 1px solid #d4d4d4;
    }

    div {
        .login-title {
            color: #3b57a5;
            font-size: 1.2em;
            margin-bottom: 10px;

            h3 {
                text-transform: uppercase;
                display: inline;
                font-weight: bold;
                color: #636363;
                font-size: 18px;
            }
        }

        a.reset-link, .btn-link {
            color: #337ab7;
            text-decoration: none;
            font-size: 12px;
            font-weight: bold;
            position: absolute;
            padding-top: 0.7em;
        }

        &.external-providers {
            li {
                margin: 1em 0;

                a.btn {
                    text-align: left;

                    img {
                        width: 24px;
                        margin-right: 2em;
                    }

                    background-color: #eff2f4;
                    border-color: #b3b9bf;

                    &:hover {
                        background-color: #e2e6ea;
                        border-color: #6c757d;
                    }
                }
            }
        }
    }

    .validation-summary{
        padding: 0.4rem 1rem;

        ul{
            margin-bottom:0;
        }
    }
}

/* Any devices that are less than "$max-device-width-for-mobile" will favor these settings. */
@media (max-width: $max-device-width-for-mobile) {
    body.login-layout {
        header {
            margin-bottom: 30px;
        }

        .additional-title {
            top: -12px;
            font-size: 1.2rem;
        }

        main.container {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .login-page {

        div.logo-col {
            border: none;
            margin-bottom: 10px;
        }

        .alert-danger.validation-summary {
            background: transparent;
            border: none;
            margin-bottom: 0;
            padding: 0;
        }
    }
}
